<template>
  <v-container fluid class="grey lighten-4" style="height: 100%">
    <v-row justify="center" class="pb-12 mb-12">
      <v-col>
        <v-card-title> Konfiguracja </v-card-title>

        <v-alert v-if="message.content" :type="message.type">
          {{ message.content }}
        </v-alert>

        <v-row align="center" justify="center">
          <v-col cols="9" sm="9" md="7">
            <v-form ref="formPre" v-model="validPre" lazy="true">
              <div>Ogólne</div>
              <v-row>
                <v-col cols="12" v-for="field in settings.notifications" :key="field.meta_name">
                  <v-text-field v-model="field.value" :label="field.name" type="email" />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" v-for="field in settings.pickups" :key="field.meta_name">
                  <v-text-field
                    v-model="field.value"
                    :label="field.name"
                    suffix="zł"
                    type="number"
                    min="0"
                  />
                </v-col>
              </v-row>

              <v-expansion-panels class="mb-4">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Odbiór od wyznaczonego producenta
                  </v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-form ref="manufactoriesForm" v-model="validManufactoriesForm" lazy="true">
                      <v-row
                        v-for="(item, index) in settings.manufactories.items"
                        :key="index"
                        justify="center"
                        align="center"
                        no-gutters
                        class="mb-4"
                      >
                        <v-col lg="10" md="10" sm="10" cols="10" class="py-1 px-2">
                          <v-text-field
                            v-model="item.name"
                            label="Nazwa producenta"
                            type="text"
                            :rules="textRule(1, 240)"
                            dense
                          />
                        </v-col>

                        <v-col
                          lg="2"
                          md="2"
                          sm="2"
                          cols="2"
                          justify="center"
                          align="center"
                          class="py-1 px-2"
                        >
                          <v-btn icon color="error" small @click="removeManufactory(item, index)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>

                        <v-col lg="8" md="8" sm="8" cols="8" class="py-1 px-2">
                          <v-text-field
                            v-model="item.city"
                            label="Miasto"
                            type="text"
                            :rules="textRule(1, 100)"
                            dense
                          />
                        </v-col>

                        <v-col lg="4" md="4" sm="4" cols="4" class="py-1 px-2">
                          <v-text-field
                            v-model="item.postcode"
                            label="Kod pocztowy"
                            type="text"
                            :rules="postcodeRule"
                            v-mask="'##-###'"
                            dense
                          />
                        </v-col>

                        <v-col lg="8" md="8" sm="8" cols="8" class="py-1 px-2">
                          <v-text-field
                            v-model="item.street"
                            label="Ulica"
                            type="text"
                            :rules="textRule(1, 140)"
                            dense
                          />
                        </v-col>

                        <v-col lg="4" md="4" sm="4" cols="4" class="py-1 px-2">
                          <v-text-field
                            v-model="item.price"
                            label="Cena"
                            type="number"
                            min="0"
                            suffix="zł"
                            :rules="float(10, 4, true, true, false)"
                            dense
                          />
                        </v-col>
                      </v-row>

                      <v-row justify="center" align="center" no-gutters class="mb-2">
                        <v-btn @click="addManufactory()" class="mx-2" fab dark color="dark" small>
                          <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                      </v-row>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-expansion-panels class="mb-4">
                <v-expansion-panel>
                  <v-expansion-panel-header>Meble tapicerowane</v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-row>
                      <v-col cols="12" v-for="field in settings.upholstery" :key="field.meta_name">
                        <v-text-field
                          v-model="field.value"
                          :label="field.name"
                          type="number"
                          min="0"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>Meble w paczkach</v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-form ref="hardFurnitureForm" v-model="validHardFurnitureForm" lazy="true">
                      <v-row
                        v-for="(item, index) in settings.hard_furniture.items"
                        :key="index"
                        justify="center"
                        align="center"
                      >
                        <v-col lg="3" md="3" sm="3" cols="6">
                          <v-text-field
                            v-model="item.from"
                            label="Paczki od"
                            type="number"
                            min="0"
                            suffix="szt"
                            :rules="integer(1, 9, true, true, false)"
                            dense
                          />
                        </v-col>

                        <v-col lg="3" md="3" sm="3" cols="6">
                          <v-text-field
                            v-model="item.to"
                            label="Paczki do"
                            type="number"
                            min="0"
                            suffix="szt"
                            :rules="integer(1, 9, true, true, false)"
                            dense
                          />
                        </v-col>

                        <v-col lg="4" md="4" sm="4" cols="10">
                          <v-text-field
                            v-model="item.price"
                            label="Cena"
                            type="number"
                            min="0"
                            suffix="zł"
                            :rules="float(10, 4, true, true, false)"
                            dense
                          />
                        </v-col>

                        <v-col lg="2" md="2" sm="2" cols="2" justify="center" align="center">
                          <v-btn icon color="error" small @click="removeHardFurniture(item, index)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-row align="center" justify="center" class="mb-2">
                        <v-col cols="8"> Powyżej tych paczek każda kolejna plus </v-col>

                        <v-col cols="4">
                          <v-text-field
                            v-model="settings.hard_furniture.extra.price"
                            label="Cena"
                            type="number"
                            min="0"
                            suffix="zł"
                            :rules="float(10, 4, true, true, false)"
                            dense
                          />
                        </v-col>
                      </v-row>

                      <v-row justify="center" align="center" no-gutters class="mb-2">
                        <v-btn @click="addHardFurniture()" class="mx-2" fab dark color="dark" small>
                          <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                      </v-row>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>Krzesła</v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-form ref="chairsForm" v-model="validChairsForm" lazy="true">
                      <v-row
                        v-for="(item, index) in settings.chairs.items"
                        :key="index"
                        justify="center"
                        align="center"
                      >
                        <v-col lg="3" md="3" sm="3" cols="6">
                          <v-text-field
                            v-model="item.from"
                            label="Kartony od"
                            type="number"
                            min="0"
                            suffix="szt"
                            :rules="integer(1, 9, true, true, false)"
                            dense
                          />
                        </v-col>

                        <v-col lg="3" md="3" sm="3" cols="6">
                          <v-text-field
                            v-model="item.to"
                            label="Kartony do"
                            type="number"
                            min="0"
                            suffix="szt"
                            :rules="integer(1, 9, true, true, false)"
                            dense
                          />
                        </v-col>

                        <v-col lg="4" md="4" sm="4" cols="10">
                          <v-text-field
                            v-model="item.price"
                            label="Cena"
                            type="number"
                            min="0"
                            suffix="zł"
                            :rules="float(10, 4, true, true, false)"
                            dense
                          />
                        </v-col>

                        <v-col lg="2" md="2" sm="2" cols="2" justify="center" align="center">
                          <v-btn icon color="error" small @click="removeChair(item, index)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-row align="center" justify="center">
                        <v-col cols="8"> Każdy kolejny karton </v-col>

                        <v-col cols="4">
                          <v-text-field
                            v-model="settings.chairs.extra.priceBox"
                            label="Cena"
                            type="number"
                            min="0"
                            suffix="zł"
                            :rules="float(10, 4, true, true, false)"
                            dense
                          />
                        </v-col>
                      </v-row>

                      <v-row align="center" justify="center">
                        <v-col cols="8"> Krzesła bez kartonów </v-col>

                        <v-col cols="4">
                          <v-text-field
                            v-model="settings.chairs.extra.priceWithoutBox"
                            label="Cena"
                            type="number"
                            min="0"
                            suffix="zł"
                            :rules="float(10, 4, true, true, false)"
                            dense
                          />
                        </v-col>
                      </v-row>

                      <v-row justify="center" align="center" no-gutters class="my-2">
                        <v-btn @click="addChair()" class="mx-2" fab dark color="dark" small>
                          <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                      </v-row>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>Stoły</v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-form ref="tablesForm" v-model="validTablesForm" lazy="true">
                      <v-row
                        v-for="(item, index) in settings.tables.items"
                        :key="index"
                        justify="center"
                        align="center"
                      >
                        <v-col lg="3" md="3" sm="3" cols="6">
                          <v-text-field
                            v-model="item.from"
                            label="Wymiar od"
                            type="number"
                            min="0"
                            suffix="cm"
                            :rules="integer(1, 9, true, true, false)"
                            dense
                          />
                        </v-col>

                        <v-col lg="3" md="3" sm="3" cols="6">
                          <v-text-field
                            v-model="item.to"
                            label="Wymiar do"
                            type="number"
                            min="0"
                            suffix="cm"
                            :rules="integer(1, 9, true, true, false)"
                            dense
                          />
                        </v-col>

                        <v-col lg="4" md="4" sm="4" cols="10">
                          <v-text-field
                            v-model="item.price"
                            label="Cena"
                            type="number"
                            min="0"
                            suffix="zł"
                            :rules="float(10, 4, true, true, false)"
                            dense
                          />
                        </v-col>

                        <v-col lg="2" md="2" sm="2" cols="2" justify="center" align="center">
                          <v-btn icon color="error" small @click="removeTable(item, index)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-row justify="center" align="center" no-gutters class="my-2">
                        <v-btn @click="addTable()" class="mx-2" fab dark color="dark" small>
                          <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                      </v-row>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>Zestaw stół + krzesła</v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-form
                      ref="setChairsTablesForm"
                      v-model="validSetChairsTablesForm"
                      lazy="true"
                    >
                      <v-row
                        v-for="(item, index) in settings.set_chairs_tables.items"
                        :key="index"
                        justify="center"
                        align="center"
                      >
                        <v-col lg="3" md="3" sm="3" cols="6">
                          <v-text-field
                            v-model="item.from"
                            label="Liczba krzeseł od"
                            type="number"
                            min="0"
                            suffix="szt"
                            :rules="integer(1, 9, true, true, false)"
                            dense
                          />
                        </v-col>

                        <v-col lg="3" md="3" sm="3" cols="6">
                          <v-text-field
                            v-model="item.to"
                            label="Liczba krzeseł do"
                            type="number"
                            min="0"
                            suffix="szt"
                            :rules="integer(1, 9, true, true, false)"
                            dense
                          />
                        </v-col>

                        <v-col lg="4" md="4" sm="4" cols="10">
                          <v-text-field
                            v-model="item.price"
                            label="Cena"
                            type="number"
                            min="0"
                            suffix="zł"
                            :rules="float(10, 4, true, true, false)"
                            dense
                          />
                        </v-col>

                        <v-col lg="2" md="2" sm="2" cols="2" justify="center" align="center">
                          <v-btn
                            icon
                            color="error"
                            small
                            @click="removeSetChairsTables(item, index)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-row justify="center" align="center" no-gutters class="mb-2">
                        <v-btn
                          @click="addSetChairsTables()"
                          class="mx-2"
                          fab
                          dark
                          color="dark"
                          small
                        >
                          <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                      </v-row>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-expansion-panels class="mb-4">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    {{ set.standard ? 'Grupy dostaw' : '' }}
                    {{ !settings.dg_items.length ? '(brak zdefiniowanych grup dostaw)' : '' }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <div>
                      <!-- must be inside div because "duplicate key" error -->
                      <v-row v-for="[key, field] in settings.dg_items.entries()" :key="key">
                        <v-col cols="12">
                          <v-text-field
                            v-model="field.label"
                            label="Identyfikator"
                            type="text"
                            @input="updateDG(key, field.label, field.value, field.day)"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-model="field.day"
                            label="Dzień dostaw"
                            :items="days"
                            @change="updateDG(key, field.label, field.value, field.day)"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="11">
                          <v-textarea
                            v-model="field.value"
                            label="Kody pocztowe"
                            hint="Prefixy kodów pocztowych rozdziel przecinkami"
                            @input="updateDG(key, field.label, field.value, field.day)"
                          />
                        </v-col>
                        <!-- <v-col
                    cols="1"
                    class="d-flex align-center justify-center"
                    @click="removeItem(key)"
                  >
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-col> -->
                      </v-row>

                      <v-row>
                        <v-spacer />
                        <v-btn @click="newDG" class="mx-2" fab dark color="dark">
                          <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                        <v-spacer />
                      </v-row>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-footer :padless="true" fixed>
      <v-card flat tile width="100%" class="amber lighten-1 text-center">
        <v-divider></v-divider>
        <v-row>
          <v-spacer />
          <v-col cols="3">
            <v-btn
              @click="save()"
              :disabled="disableSaveBtn || !validPre"
              color="dark darken-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
              >Zapisz</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-footer>

    <!-- confirm dialog -->
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import { mask } from 'vue-the-mask';
  import ConfirmDialog from '../../../components/Confirm.vue';
  export default {
    name: 'Config',
    components: {
      ConfirmDialog,
    },
    directives: {
      mask,
    },
    data: () => ({
      disableSaveBtn: true,
      validPre: false,
      validManufactoriesForm: false,
      validHardFurnitureForm: false,
      validChairsForm: false,
      validTablesForm: false,
      validSetChairsTablesForm: false,
      message: {
        content: null,
        type: 'error',
      },
      settings: {
        upholstery: {},
        hard: {},
        dg: {},
        hard_items: {},
        dg_items: [],
        notifications: {},
        pickups: {},
        manufactories: { items: [] },
        hard_furniture: { items: [], extra: {} },
        chairs: { items: [], extra: {} },
        tables: { items: [], extra: {} },
        set_chairs_tables: { items: [], extra: {} },
      },
      required: [v => !!v || 'Pole jest wymagane'],
      set: {
        standard: false,
      },
      days: ['poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota', 'niedziela'],
      postcodeRule: [
        v => !!v || 'Pole jest wymagane',
        v => /^[0-9]{2}-[0-9]{3}$/.test(v) || 'Kod pocztowy ma niewłaściwy format',
      ],
    }),
    methods: {
      textRule: function (from, to, required = true) {
        const rules = [];
        if (required) rules.push(v => !!v || 'Pole jest wymagane');
        rules.push(
          v =>
            new RegExp(`^.{${from},${to}}$`).test(v) ||
            `Pole musi zawierać od ${from} do ${to} znaków`
        );

        return rules;
      },
      integer: function (from, to, required = true, positive = true, notZero = true) {
        let rules = [];

        if (required) {
          rules.push(v => !!v || 'Pole jest wymagane');
          rules.push(v => new RegExp(`^(0|[1-9]\\d*)$`).test(v) || 'Zły format liczby');
          rules.push(
            v =>
              new RegExp(`^.{${from},${to}}$`).test(v) ||
              `Liczba musi zawierac od ${from} do ${to} cyfr`
          );
        } else {
          rules.push(v => new RegExp(`^$|^(0|[1-9]\\d*)$`).test(v) || 'Zły format liczby');
          rules.push(
            v =>
              new RegExp(`^$|^.{${from},${to}}$`)
                .test(v)(new RegExp(`^.{${from},${to}}$`))
                .test(v) || `Liczba musi zawierac od ${from} do ${to} cyfr`
          );
        }

        if (positive) {
          rules.push(v => v >= 0 || 'Pole musi być dodatnią liczbą');
        }

        if (notZero) {
          rules.push(v => v != 0 || 'Pole musi być różne od zera');
        }

        return rules;
      },
      float: function (to_the_left, to_the_right, required = true, positive = true, notZero = true) {
        let rules = [];

        let left = '0';
        if (to_the_left > 0) {
          left = `-?(0|([1-9][0-9]{0,${to_the_left - 1}}))`;
        }

        let right = '';
        if (to_the_right > 0) {
          right = `((\\.|,)[0-9]{0,${to_the_right - 1}}[1-9])?`;
        }

        if (required) {
          rules.push(v => !!v || 'Pole jest wymagane');
          rules.push(v => new RegExp(`^(${left + right})$`).test(v) || 'Zły format liczby');
        } else {
          rules.push(v => new RegExp(`^$|^(${left + right})$`).test(v) || 'Zły format liczby');
        }

        if (positive) {
          rules.push(v => v >= 0 || 'Pole musi być dodatnią liczbą');
        }

        if (notZero) {
          if (required) {
            rules.push(v => v != 0 || 'Pole musi być różne od zera');
          } else {
            rules.push(v => v == '' || v != 0 || 'Pole musi być różne od zera');
          }
        }

        return rules;
      },
      sortByInterval: function (settingsProp) {
        this.settings[settingsProp].items.sort((a, b) => {
          if (parseInt(a.from) < parseInt(b.from)) return -1;
          if (parseInt(a.from) > parseInt(b.from)) return 1;

          if (parseInt(a.to) < parseInt(b.to)) return -1;
          if (parseInt(a.to) > parseInt(b.to)) return 1;

          return 0;
        });
      },
      validateManufactories: function () {
        this.sortByInterval('manufactories');

        if (!this.$refs.manufactoriesForm.validate()) {
          throw 'Stworzony cennik jest niepoprawny. Uzupełnione wartości wydają się niepoprawne.';
        }
      },
      addManufactory: function () {
        try {
          this.validateManufactories();
        } catch (error) {
          this.message = {
            content: error,
            type: 'error',
          };
          return window.scrollTo(0, 0);
        }

        this.settings.manufactories.items.push({
          name: '',
          city: '',
          postcode: '',
          street: '',
          price: '',
        });
      },
      removeManufactory: async function (item, index) {
        if (
          !(await this.$refs.confirmDialog.open(
            'Usuń pozycje',
            'Czy jesteś pewny, że chcesz usunąć pozycję z cennika?',
            { color: 'red' }
          ))
        ) {
          return;
        }

        this.settings.manufactories.items.splice(index, 1);
      },
      validateHardFurniture: function () {
        this.sortByInterval('hard_furniture');

        if (!this.$refs.hardFurnitureForm.validate()) {
          throw 'Stworzony cennik jest niepoprawny. Uzupełnione wartości wydają się niepoprawne.';
        }

        let prev_val = 0;
        for (let index = 0; index < this.settings.hard_furniture.items.length; index++) {
          if (prev_val > parseInt(this.settings.hard_furniture.items[index].from)) {
            throw 'Stworzony cennik jest niepoprawny. Pewne przedziały niepotrzebnie się nakładają.';
          }

          if (
            parseInt(this.settings.hard_furniture.items[index].to) <
            parseInt(this.settings.hard_furniture.items[index].from)
          ) {
            throw 'Stworzony cennik jest niepoprawny. Liczba paczek "od" nie może być mniejszy niż "do".';
          }

          prev_val = parseInt(this.settings.hard_furniture.items[index].to);
        }
      },
      addHardFurniture: function () {
        try {
          this.validateHardFurniture();
        } catch (error) {
          this.message = {
            content: error,
            type: 'error',
          };
          return window.scrollTo(0, 0);
        }

        this.settings.hard_furniture.items.push({
          from: '',
          to: '',
          price: '',
        });
      },
      removeHardFurniture: async function (item, index) {
        if (
          !(await this.$refs.confirmDialog.open(
            'Usuń pozycje',
            'Czy jesteś pewny, że chcesz usunąć pozycję z cennika?',
            { color: 'red' }
          ))
        ) {
          return;
        }

        this.settings.hard_furniture.items.splice(index, 1);
      },
      validateChairs: function () {
        this.sortByInterval('chairs');

        if (!this.$refs.chairsForm.validate()) {
          throw 'Stworzony cennik jest niepoprawny. Uzupełnione wartości wydają się niepoprawne.';
        }

        let prev_val = 0;
        for (let index = 0; index < this.settings.chairs.items.length; index++) {
          if (prev_val > parseInt(this.settings.chairs.items[index].from)) {
            throw 'Stworzony cennik jest niepoprawny. Pewne przedziały niepotrzebnie się nakładają.';
          }

          if (
            parseInt(this.settings.chairs.items[index].to) <
            parseInt(this.settings.chairs.items[index].from)
          ) {
            throw 'Stworzony cennik jest niepoprawny. Liczba paczek "od" nie może być mniejszy niż "do".';
          }

          prev_val = parseInt(this.settings.chairs.items[index].to);
        }
      },
      addChair: function () {
        try {
          this.validateChairs();
        } catch (error) {
          this.message = {
            content: error,
            type: 'error',
          };
          return window.scrollTo(0, 0);
        }

        this.settings.chairs.items.push({
          from: '',
          to: '',
          price: '',
        });
      },
      removeChair: async function (item, index) {
        if (
          !(await this.$refs.confirmDialog.open(
            'Usuń pozycje',
            'Czy jesteś pewny, że chcesz usunąć pozycję z cennika?',
            { color: 'red' }
          ))
        ) {
          return;
        }

        this.settings.chairs.items.splice(index, 1);
      },
      validateTables: function () {
        this.sortByInterval('tables');

        if (!this.$refs.tablesForm.validate()) {
          throw 'Stworzony cennik jest niepoprawny. Uzupełnione wartości wydają się niepoprawne.';
        }

        let prev_val = 0;
        for (let index = 0; index < this.settings.tables.items.length; index++) {
          if (prev_val > parseInt(this.settings.tables.items[index].from)) {
            throw 'Stworzony cennik jest niepoprawny. Pewne przedziały niepotrzebnie się nakładają.';
          }

          if (
            parseInt(this.settings.tables.items[index].to) <
            parseInt(this.settings.tables.items[index].from)
          ) {
            throw 'Stworzony cennik jest niepoprawny. Liczba paczek "od" nie może być mniejszy niż "do".';
          }

          prev_val = parseInt(this.settings.tables.items[index].to);
        }
      },
      addTable: function () {
        try {
          this.validateTables();
        } catch (error) {
          this.message = {
            content: error,
            type: 'error',
          };
          return window.scrollTo(0, 0);
        }

        this.settings.tables.items.push({
          from: '',
          to: '',
          price: '',
        });
      },
      removeTable: async function (item, index) {
        if (
          !(await this.$refs.confirmDialog.open(
            'Usuń pozycje',
            'Czy jesteś pewny, że chcesz usunąć pozycję z cennika?',
            { color: 'red' }
          ))
        ) {
          return;
        }

        this.settings.tables.items.splice(index, 1);
      },
      validateSetChairsTables: function () {
        this.sortByInterval('set_chairs_tables');

        if (!this.$refs.setChairsTablesForm.validate()) {
          throw 'Stworzony cennik jest niepoprawny. Uzupełnione wartości wydają się niepoprawne.';
        }

        let prev_val = 0;
        for (let index = 0; index < this.settings.set_chairs_tables.items.length; index++) {
          if (prev_val > parseInt(this.settings.set_chairs_tables.items[index].from)) {
            throw 'Stworzony cennik jest niepoprawny. Pewne przedziały niepotrzebnie się nakładają.';
          }

          if (
            parseInt(this.settings.set_chairs_tables.items[index].to) <
            parseInt(this.settings.set_chairs_tables.items[index].from)
          ) {
            throw 'Stworzony cennik jest niepoprawny. Liczba paczek "od" nie może być mniejszy niż "do".';
          }

          prev_val = parseInt(this.settings.set_chairs_tables.items[index].to);
        }
      },
      addSetChairsTables: function () {
        try {
          this.validateSetChairsTables();
        } catch (error) {
          this.message = {
            content: error,
            type: 'error',
          };
          return window.scrollTo(0, 0);
        }

        this.settings.set_chairs_tables.items.push({
          from: '',
          to: '',
          price: '',
        });
      },
      removeSetChairsTables: async function (item, index) {
        if (
          !(await this.$refs.confirmDialog.open(
            'Usuń pozycje',
            'Czy jesteś pewny, że chcesz usunąć pozycję z cennika?',
            { color: 'red' }
          ))
        ) {
          return;
        }

        this.settings.set_chairs_tables.items.splice(index, 1);
      },
      updateDG: function (id, label, value, day) {
        this.settings.dg_items[id] = {
          label: label,
          value: value,
          day: day,
        };

        this.settings.dg.delivery_group.value = JSON.stringify(this.settings.dg_items);
      },
      newDG: function () {
        this.settings.dg_items.push({
          label: null,
          value: null,
        });
      },
      updatePosition: function (parent, id, label, value) {
        this.settings.hard_items[parent][id] = {
          label: label,
          value: parseInt(value),
        };

        this.settings.hard.price_table.value = JSON.stringify(this.settings.hard_items);
      },
      removeItem: function (parent, id) {
        this.settings.hard_items[parent];
        let table = this.settings.hard_items[parent];
        table.splice(id, 1);

        this.$set(this.settings.hard_items, parent, table);

        //bad sollution but works -> v-for doesn't watch nested objects -> can't impact on array inside object with key "parent"
        let name = 'K' + Object.entries(this.settings.hard_items).length;
        this.$set(this.settings.hard_items, name, [
          {
            label: '',
            value: '',
          },
        ]);

        this.$delete(this.settings.hard_items, name);
        //end of bad sullution

        this.settings.hard.price_table.value = JSON.stringify(this.settings.hard_items);
      },
      newItem: function (key) {
        this.settings.hard_items[key].push({
          label: '',
          value: '',
        });

        //bad sollution but works -> v-for doesn't watch nested objects -> can't impact on array inside object with key "parent"
        let name = 'K' + Object.entries(this.settings.hard_items).length;
        this.$set(this.settings.hard_items, name, [
          {
            label: '',
            value: '',
          },
        ]);

        this.$delete(this.settings.hard_items, name);
        //end of bad sullution
      },
      newCategory: function () {
        let name = 'Kategoria ' + Object.entries(this.settings.hard_items).length;
        this.$set(this.settings.hard_items, name, [
          {
            label: '',
            value: '',
          },
        ]);
        this.settings.hard.price_table.value = JSON.stringify(this.settings.hard_items);
      },
      updateCategoryName: function (oldkey, newkey) {
        //working sollution but problems with order

        // this.settings.hard_items[newkey] = this.settings.hard_items[ oldkey ];
        // this.$set( this.settings.hard_items, newkey, this.settings.hard_items[ oldkey ]);
        // this.$delete( this.settings.hard_items, oldkey );

        let keyMap = {};
        keyMap[oldkey] = newkey; //assigning keys that should be switched
        let keys = Object.keys(this.settings.hard_items); //map keys from object

        let result = keys.reduce((acc, val) => {
          let value = this.settings.hard_items[val]; //assigning value to temporary variable
          if (keyMap[val]) {
            //check if key shoud be switched
            val = keyMap[val]; //changing old key to new key
          }
          acc[val] = value;
          return acc; //returning new object
        }, {});

        this.settings.hard_items = result; //assigning new object to data

        this.settings.hard.price_table.value = JSON.stringify(this.settings.hard_items);
      },
      removeCategory: function (key) {
        this.$delete(this.settings.hard_items, key);
        this.settings.hard.price_table.value = JSON.stringify(this.settings.hard_items);
      },
      save: function () {
        try {
          this.validateManufactories();
          this.validateHardFurniture();
          this.validateChairs();
          this.validateTables();
        } catch (error) {
          this.message = {
            content: error,
            type: 'error',
          };
          return window.scrollTo(0, 0);
        }

        const settings = {
          ...this.settings.upholstery,
          ...this.settings.hard,
          ...this.settings.dg,
          ...this.settings.notifications,
          ...this.settings.pickups,
          manufactories: {
            name: 'Cennik odbioru od producentów',
            value: JSON.stringify(this.settings.manufactories),
          },
          hard_furniture: {
            name: 'Cennik mebli w paczkach',
            value: JSON.stringify(this.settings.hard_furniture),
          },
          chairs: {
            name: 'Cennik krzeseł',
            value: JSON.stringify(this.settings.chairs),
          },
          tables: {
            name: 'Cennik stołów',
            value: JSON.stringify(this.settings.tables),
          },
          set_chairs_tables: {
            name: 'Cennik zestawu stół + krzesła',
            value: JSON.stringify(this.settings.set_chairs_tables),
          },
        };

        axios({
          url: this.$store.state.url + '/api/transport/update-settings',
          data: {
            token: this.$store.state.token,
            settings: settings,
          },
          method: 'POST',
        })
          .then(resp => {
            this.message.content = resp.data.message;

            if (resp.data.type) this.message.type = resp.data.type;

            window.scrollTo(0, 0);
          })
          .catch(() => {});
      },
      getSettings: function () {
        axios({
          url: this.$store.state.url + '/api/transport/get-settings',
          data: {
            token: this.$store.state.token,
          },
          method: 'POST',
        })
          .then(resp => {
            let upholstery = ['monthly_price', 'monthly_size', 'monthly_weight'];

            resp.data.forEach(meta => {
              if (meta.meta_name === 'notification_email') {
                this.settings.notifications[meta.meta_name] = {
                  value: meta.meta_value,
                  name: meta.name,
                };
              }

              if (upholstery.indexOf(meta.meta_name) !== -1) {
                this.settings.upholstery[meta.meta_name] = {
                  value: meta.meta_value,
                  name: meta.name,
                };
              }

              if (meta.meta_name === 'pickup_price') {
                this.settings.pickups[meta.meta_name] = {
                  value: meta.meta_value,
                  name: meta.name,
                };
              }

              if (meta.meta_name === 'price_table') {
                if (meta.meta_value) {
                  let it = JSON.parse(meta.meta_value);

                  for (let i of Object.entries(it)) {
                    this.settings.hard_items[i[0]] = i[1];
                  }
                }
                this.settings.hard[meta.meta_name] = {
                  value: meta.meta_value,
                  name: meta.name,
                };
              }

              if (meta.meta_name === 'manufactories') {
                this.settings.manufactories = meta.meta_value
                  ? JSON.parse(meta.meta_value)
                  : { items: [], extra: {} };
                if (!this.settings.manufactories.items) this.settings.manufactories.items = [];
                if (!this.settings.manufactories.extra) this.settings.manufactories.extra = {};
              }

              if (meta.meta_name === 'hard_furniture') {
                this.settings.hard_furniture = meta.meta_value
                  ? JSON.parse(meta.meta_value)
                  : { items: [], extra: {} };
                if (!this.settings.hard_furniture.items) this.settings.hard_furniture.items = [];
                if (!this.settings.hard_furniture.extra) this.settings.hard_furniture.extra = {};
              }

              if (meta.meta_name === 'chairs') {
                this.settings.chairs = meta.meta_value
                  ? JSON.parse(meta.meta_value)
                  : { items: [], extra: {} };
                if (!this.settings.chairs.items) this.settings.chairs.items = [];
                if (!this.settings.chairs.extra) this.settings.chairs.extra = {};
              }

              if (meta.meta_name === 'tables') {
                this.settings.tables = meta.meta_value
                  ? JSON.parse(meta.meta_value)
                  : { items: [], extra: {} };
                if (!this.settings.tables.items) this.settings.tables.items = [];
                if (!this.settings.tables.extra) this.settings.tables.extra = {};
              }

              if (meta.meta_name === 'set_chairs_tables') {
                this.settings.set_chairs_tables = meta.meta_value
                  ? JSON.parse(meta.meta_value)
                  : { items: [], extra: {} };
                if (!this.settings.set_chairs_tables.items)
                  this.settings.set_chairs_tables.items = [];
                if (!this.settings.set_chairs_tables.extra)
                  this.settings.set_chairs_tables.extra = {};
              }

              if (meta.meta_name === 'delivery_group') {
                if (meta.meta_value) {
                  let it = JSON.parse(meta.meta_value);

                  for (let i = 0; i < it.length; i++) {
                    this.settings.dg_items.push({
                      label: it[i].label,
                      day: it[i].day,
                      value: it[i].value,
                    });
                  }
                }
                this.settings.dg[meta.meta_name] = {
                  value: meta.meta_value,
                  name: meta.name,
                };
              }
            });

            this.set.standard = true;
            this.disableSaveBtn = false;
          })
          .catch(() => {});
      },
      goto(refName) {
        const element = this.$refs[refName];
        const top = element ? element.offsetTop : 0;

        window.scrollTo(0, top);
      },
    },
    created() {
      this.getSettings();
    },
  };
</script>
